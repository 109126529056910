<script>
import TableView from '@/components/TableVIew'
import { getAction } from '@/command/netTool'
export default {
  props: {
    url: {
      type: String,
      default: '',
    },
    relationList: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      list: [],
      listParams: {
        total: 1,
      },
      rows: [],
      selectedRows:[]
    }
  },
  mounted() {
    this.getList()
    if (Array.isArray(this.relationList) && this.relationList.length > 0) {
      this.rows = this.relationList.map((e) => ({ ...e, id: e.id || e.productId }))
    }
  },
  methods: {
    getList(current = 1, size = 10) {
      let params = { upDown: '0' }
      if (this.item.code == 'activity') {
        params = {
          groupType: '0',
        }
      }

      getAction(`${this.url}`, params).then((e) => {
        this.listParams = { total: e.data.length }
        this.list = e.data
      })
    },
    getData() {
      return {
        rows: this.selectedRows.map((e) => ({
          ...e,
          itemCategoryCode: e.itemCategoryCode || this.item.code,
          itemCategoryName: e.itemCategoryName || this.item.name,
        })),
        itemCategoryCode: this.item.code,
        itemCategoryName: this.item.name,
      }
    },
    getColumns() {
      if (this.item.code == 'custome_travel_route') {
        return [
          {
            dataIndex: 'productName',
            title: '名称',
          },
        ]
      } else if (this.item.code == 'activity') {
        return [
          {
            dataIndex: 'name',
            title: '活动名称',
          },
        ]
      } else if (this.item.code == 'show_special') {
        return [
          {
            dataIndex: 'showName',
            title: '专题页名称',
          },
        ]
      } else {
        return [
          {
            dataIndex: 'productName',
            title: '名称',
          },
          {
            dataIndex: 'linkShopName',
            title: '店铺名称',
          },
        ]
      }
    },
  },
  render() {
    return (
      <TableView
        ref={'table'}
        dataSource={this.list}
        pagination={this.listParams}
        // onChange={(data) => {
        //   debugger
        //   this.getList(data.current, data.pageSize)
        // }}
        onSelectRow={(data, selectedRows) => {
          this.selectedRows = selectedRows
        }}
        selectedRowKeys={this.relationList.map((e) => e.productId || e.id)}
        columns={this.getColumns()}
      />
    )
  },
}
</script>
