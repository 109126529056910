<script>
import TableView from '@/components/TableVIew'
import { delAction, getAction, postAction } from '@/command/netTool'
import { InputNumber, Select, Input } from 'ant-design-vue'
import FormUpload from '@/components/FormView/FormUpload'

export default {
  props: {
    listData: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Object,
      default: () => '',
    },
  },
  data() {
    return {
      list: [],
      active: '',
    }
  },
  mounted() {
    this.list = this.listData
    this.active = this.data
  },
  methods: {
    sumbit() {
      this.$emit('success', this.active)
    },
  },
  render() {
    return (
      <div class="styleList">
        {this.list.map((item, index) => (
          <div
            class={['li', item.code === this.active && 'li_on']}
            onClick={() => {
              this.active = item.code
            }}
          >
            <div class="li_name">
              {index + 1}、{item.name}
            </div>
            <div class="li_img">
              <img src={item.previewUrl} />
            </div>
          </div>
        ))}
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.styleList {
  overflow: hidden;
  height: 600px;
  overflow-y: auto;
  border: 1px solid #f5f5f5;
  .li_on {
    border: 1px solid #557fff !important;
  }
  .li {
    width: 25%;
    height: 250px;
    float: left;
    border: 1px solid #f5f5f5;
    padding: 10px;
    &_name {
      min-height: 30px;
    }

    &_img {
      width: 100%;
      height: 180px;
      margin-top: 10px;
      > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
</style>
